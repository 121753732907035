<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>会员卡管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <div class="item-wrapper" v-for="(item, index) in list">
          <el-form label-width="100px">
            <el-form-item label="活动ID" >
              <el-input v-model="item.ticketCode" placeholder="活动ID" />
            </el-form-item>
            <el-form-item label="结束有效期" >
              <el-date-picker
                  v-model="item.endTime"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="结束有效期">
                  </el-date-picker>
            </el-form-item>
            <div style="text-align: right;">
              <el-button type="primary" @click="save(index)">保 存</el-button>
            </div>
          </el-form>
      </div>

    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list: []
    }
  },
  created () {
    this.GetList();
  },
  methods: {
    GetList () {
      this.$api.vipCardList({}).then((res) => {
        if (res.code !== 200)  return this.$message.error('请求失败')
        res.data.map(o => o.status = o.status.toString())
        this.list = res.data
      })
    },
    save (index) {
      const data = this.list[index];
      this.$api.vipCardUpdate({
        id: data.id,
        ticketCode: data.ticketCode,
        endTime: new Date(data.endTime),
      }).then((res) => {
        if (res.code !== 200) return this.$message.error('保存失败：' + res.message)
        this.$message.success('保存成功')
        this.GetList()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.item-wrapper{
  width: 50%;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
</style>
